import axios from "axios";
import { indonesia_payment } from "../../js/path";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      activeTab: "",
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "edit",
          label: "Edit",
        },
      ],
      form: {
        id: "",
        name: "",
        status: false,
      },
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    changestatus() {
      if (this.status) {
        this.status = false;
      } else {
        this.status = true;
      }
    },

    async fetchData() {
      this.$store.commit("loader/updateStatus", true);
      let request = `${indonesia_payment?.getPaymentType}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}`;

      try {
        const res = await axios.get(`${apiEndpoint}${request}`);
        if (res) {
          this.tableData = res?.data?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader.updateStatus", false);
      }
    },

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${indonesia_payment?.updateStatusPaymentType}/${id}`;
        const data = await axios.post(url, {
          status: !this.tableData.data[index].status,
        });
        if (data.status) {
          this.tableData.data[index].status =
            !this.tableData.data[index].status;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);

        let dataAppend = {};
        for (var key in this.form) {
          if (key == "id") continue;
          dataAppend[key] = this.form[key];
        }

        if (this.$route.name == "edit-payment-type") {
          let url = `${apiEndpoint}${indonesia_payment?.getPaymentType}/${this.form.id}`;
          const request = await axios.put(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/payment-type");
          }
        } else {
          let url = `${apiEndpoint}${indonesia_payment?.getPaymentType}`;
          const request = await axios.post(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/payment-type");
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async fetchPaymentType(id) {
      this.$store.commit("loader/updateStatus", true);
      let request = `${indonesia_payment?.getPaymentType}/${id}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            const responseData = res.response;
            if (responseData.id != "" && responseData.id != null) {
              this.form.id = responseData.id;
            }
            if (responseData.name != "" && responseData.name != null) {
              this.form.name = responseData.name;
            }
            if (responseData.status != "" && responseData.status != null) {
              if (responseData.status == 1) {
                this.form.status = true;
              } else {
                this.form.status = false;
              }
            }
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
  },

  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(v);
          this.currentPage = this.pageNo;
          this.currentPage = v;
        }
      },
    },
  },
  created() {
    if (
      this.$route.name == "add-payment-type" ||
      this.$route.name == "edit-payment-type"
    ) {
      if (this.$route.name == "edit-payment-type") {
        this.fetchPaymentType(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};
